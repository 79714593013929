<template>
    <div class="shipping-box">
        <h1 class="head">Checkout</h1>

        <div class="shipping-box-main">
            <div class="payment-details">
                <div class="pay-instruction">
                    <h1>Pay full or Half</h1>
                    <p>
                        ################
                    </p>
                </div>

                <div class="card-details">
                    <h1>ENTER CARD DETAILS</h1>

                    <div class="card-details-form">
                        <div class="card">
                            <label>Card Number </label>
                            <InputBox />
                        </div>

                        <div class="card">
                            <label>Card Holder's Name</label>
                            <InputBox />
                        </div>

                        <div class="subform">
                            <div class="card">
                                <label>Expiry Date</label>
                                <InputBox class="year" />
                            </div>

                            <div class="card">
                                <label>CVV</label>
                                <InputBox />
                            </div>
                        </div>

                        <div class="form-checkbox">
                            <Checkbox label="I Agree to the T&C" />
                        </div>

                        <div class="p-btn">
                            <router-link
                                :to="{
                                    name: 'ThankYouPage',
                                    params: { id: $route.query.order_id },
                                }"
                            >
                                <Button primary name="Pay" />
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>

            <PriceDetails :data="priceDetails" />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import RegisterStoreModule from 'mixins/RegisterStoreModule'
import ConvertToPrice from 'mixins/ConvertToPrice'
import FAPOPageModule from 'store/modules/FAPOPage'
// import HeadAndSubTexts from 'componentsv2/HeadAndSubTexts'
import Checkbox from 'componentsv2/Checkbox'
import Button from 'componentsv2/Button'
import InputBox from 'componentsv2/InputBox'
import PriceDetails from 'componentsv2/PriceDetails'

export default {
    name: 'PaymentPage',
    components: {
        // HeadAndSubTexts,
        Checkbox,
        Button,
        InputBox,
        PriceDetails,
    },
    mixins: [RegisterStoreModule, ConvertToPrice],
    computed: {
        ...mapState({
            priceDetails: state => state.FAPOPage.priceDetails,
        }),
    },
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'SILOHO',
        // all titles will be injected into this template
        titleTemplate: '%s | Payment',
        bodyClass: 'FAPO-page-body',
    },
    created() {
        this.registerStoreModule('FAPOPage', FAPOPageModule)
        this.fetchPriceDetails(this.$route.query.order_id)
    },
    destroyed() {},
    methods: {
        ...mapActions({
            fetchPriceDetails: 'fetchPriceDetails',
        }),
    },
}
</script>

<style lang="scss" scoped>
@import './PaymentPage.scss';
</style>
