import { getPrice } from 'api/orderApi'


const FAPOPage = {
    state: () => ({
        priceDetails:[],
    }),

    mutations: {
        setPriceDetails(state, payload){
            state.priceDetails = payload
        },   
    },

    getters: {},

    actions: {
        fetchPriceDetails({commit},id){
            return getPrice(id)
                .then(payload => {
                    if (payload.status === 404) {
                        console.log('fetching failed')
                    } else {
                        commit('setPriceDetails', payload)
                        return payload
                    }
                })
        },
    }
}

export default FAPOPage;