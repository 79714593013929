import Api from 'utils/Api'

export function createOrder(id){
   return Api
        .post(
            'order/design-order/',
            {
                "ehd_id":id
            }
        )
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        }) 
}

export function getPrice(id){
    return Api
         .get(
            `order/base-order/${id}/`,
         )
         .then(response => {
             return response.data
         })
         .catch(e => {
             return { status: e.response.status, data: e.response.data }
         }) 
 }

export function getExecutionTracker(id){
    return Api
    .get(`Execution/execution-tracker?order_id=${id}`)
    .then(response => {
        return response.data
    })
    .catch(e => {
        return { status: e.response.status, data: e.response.data }
    }) 
}
 
export function getExecutionAllTask(id){
    return Api
    .get(`Execution/all-execution-task-status?order_id=${id}`)
    .then(response => {
        return response.data
    })
    .catch(e => {
        return { status: e.response.status, data: e.response.data}
    })
}