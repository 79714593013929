<template>
    <div class="price-details">
        <div class="head">
            <h1>Price Details</h1>
            <!-- <p>(21 Products)</p> -->
        </div>

        <div class="breakup">
            <div class="breakup-details">
                <div class="cost-inline">
                    <h1>Total MRP</h1>
                    <p>
                        ₹
                        {{ convertToIndianNumberSystem(data.total_mrp) }}
                    </p>
                </div>
                <div class="cost-inline" v-if="data.discount != 0">
                    <h1>Discount</h1>
                    <p>
                        -₹
                        {{ convertToIndianNumberSystem(data.discount) }}
                    </p>
                </div>
                <div class="cost-inline">
                    <h1>Coupon</h1>
                    <p class="apply-coupon">Apply Coupon</p>
                </div>
            </div>
            <div class="total-cost">
                <div class="cost-inline">
                    <h1>Total Cost</h1>
                    <p>
                        ₹
                        {{ convertToIndianNumberSystem(data.final_amount) }}
                    </p>
                </div>
            </div>
        </div>

        <div class="comment" v-if="data.discount != 0">
            <p>You saved ₹ {{ convertToIndianNumberSystem(data.discount) }}</p>
        </div>
    </div>
</template>

<script>
import ConvertToPrice from 'mixins/ConvertToPrice'

export default {
    name: 'PriceDetails',
    props: ['data'],
    mixins: [ConvertToPrice],
}
</script>

<style lang="scss" scoped>
@import './PriceDetails.scss';
</style>
