<template>
    <div class="q-input-group-wrapper">
        <label class="q-label">{{ labelName }}</label>
        <div class="q-input-group">
            <input
                :placeholder="placeholder"
                class="q-input"
                :class="{
                    'q-input-error': error,
                }"
                :type="type"
                @keyup="inputValue"
                @change="emitValue"
            />
            <!-- <select
                class="q-input"
                :name= "name"
                :value= "value"
            /> -->
            <!-- <slot></slot> -->
            <svg
                v-if="error"
                class="q-input-error-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill="#000"
                    d="M12 22c-1.978 0-3.911-.587-5.556-1.685-1.644-1.1-2.926-2.66-3.683-4.488-.757-1.827-.955-3.838-.569-5.778.386-1.94 1.338-3.722 2.737-5.12 1.398-1.399 3.18-2.351 5.12-2.737 1.94-.386 3.95-.188 5.778.57 1.827.756 3.389 2.038 4.488 3.682C21.413 8.09 22 10.022 22 12c-.003 2.651-1.057 5.193-2.932 7.068S14.65 21.998 12 22zm-1-7v2h2v-2h-2zm0-8v6h2V7h-2z"
                />
            </svg>
        </div>
        <p
            v-if="error"
            class="q-error"
            :class="{
                'q-error-show': error,
            }"
        >
            {{ msg }}
        </p>
    </div>
</template>

<script>
export default {
    name: 'InputBox',
    props: {
        value: {
            type: String,
        },
        placeholder: {
            type: String,
        },
        labelName: {
            type: String,
            required: true,
        },
        inputType: {
            type: String,
        },
        type: {
            type: String,
        },
    },
    data() {
        return {
            error: false,
            msg: '',
        }
    },
    methods: {
        emitValue(e) {
            this.$emit('emit-value', e.currentTarget.value)
        },
        inputValue(e) {
            if (e.currentTarget.value.length > 0) {
                switch (this.inputType) {
                    case 'Name':
                        var name = new RegExp('^[a-zA-Z_ ]*$')
                        if (name.test(e.currentTarget.value)) {
                            this.error = false
                            this.msg = ''
                        } else {
                            this.error = true
                            this.msg = 'Please enter valid name'
                        }
                        break
                    case 'Email':
                        var email = new RegExp(
                            /^([a-zA-Z0-9_\-\\.]+)@([a-zA-Z0-9_\-\\.]+)\.([a-zA-Z]{2,5})$/,
                        )
                        if (email.test(e.currentTarget.value)) {
                            this.error = false
                            this.msg = ''
                        } else {
                            this.error = true
                            this.msg = 'Please enter valid email'
                        }
                        break
                    case 'Phone':
                        var number = new RegExp('^[0-9]*$')
                        if (
                            number.test(e.currentTarget.value) &&
                            e.currentTarget.value.length == 10
                        ) {
                            this.error = false
                            this.msg = ''
                        } else {
                            this.error = true
                            this.msg = 'Please enter 10 digit mobile no.'
                        }
                        break
                    case 'Message':
                        var message = new RegExp('^[a-zA-Z, ]*$')
                        if (message.test(e.currentTarget.value)) {
                            this.error = false
                            this.msg = ''
                        } else {
                            this.error = true
                            this.msg = 'Please enter valid message'
                        }
                        break
                }
            } else {
                this.error = true
                this.msg = "Please don't leave it empty"
            }
        },
    },
}
</script>

<style lang="scss">
@import './InputBox.scss';
</style>
